import '../css/Main.css';
import {useEffect} from "react";

function AnimatedCanvas() {
    const image_width = window.innerWidth * 0.7;
    const image_height = image_width * 631 / 3227;
    useEffect(() => {
        const canvas = document.getElementsByClassName("canvas")[0];
        // @ts-ignore
        const context = canvas.getContext("2d");
        const image_numbers = [0, 1, 2, 3, 4, 5, 6, 5, 7, 8, 9, 10, 11, 12, 13, 14];
        let i = 0;
        const animate = (count: number) => {
            let until = -1;
            switch(count) {
                case 1: {until = 6; break;}
                case 2: {until = 8; break;}
                case 3: {until = 15; break;}
                case 4: {until = 16; break;}
            }
            if (i >= until) {
                clearInterval(timer);
                if (count <= 3) timer = setInterval(() => animate(count + 1), 100 + 350 * (count % 2));
                return;
            }
            const img = new Image();
            img.src = "../image/canvastitle/" + image_numbers[i].toString() + ".png";
            img.onload = () => {
                context.clearRect(0, 0, image_width, image_height);
                context.drawImage(img, 0, 0, image_width, image_height);
            }
            i++;
        }
        let timer = setInterval(() => animate(1), 150);
    }, [image_height, image_width]);
    return <canvas width={image_width} height={image_height} className="canvas"/>;
}

function MainIntroduction() {
    return (
        <div id="introduction">
            <AnimatedCanvas/>
            <div className="subtitle">
                <p className="subtitle1">나만의 세미나 연습 문제</p>
                <div className="examples">
                    <hr/>
                    <p className="example">딥러닝 기초</p>
                    <hr/>
                </div>
            </div>
        </div>
    );
}

interface MainDescriptionProps {
    whatIs: string;
    descriptions: string[][];
}

function MainDescription({ whatIs, descriptions }: MainDescriptionProps) {
    return (
        <div id="description">
            <h3 className="whatIs">{whatIs}</h3>
            <p className="description1">세미나별로 분류된 딥러닝, 해킹 등의 코딩 연습 문제를 제공하고 코드를 채점하는 Online Judge 웹사이트입니다.</p>
            <ul>
                {descriptions.map((description: string[]) =>
                    <li>
                        <h5>{description[0]}</h5>
                        <p className="descriptionLi">{description[1]}</p>
                    </li>
                )}
            </ul>
        </div>
    );
}

MainDescription.defaultProps = {
    whatIs: "SADA.learn()이란?",
    descriptions: [
        [
            "다양한 분야의 연습 문제",
            "SADA.learn()은 딥러닝 기초, 해킹(SQL injection, XSS 등), 강화학습 등의 분야에서 실력을 키울 수 있는 연습 문제를 제공하고 있습니다."
        ],
        [
            "나만의 세미나 연습문제 제작",
            "SADA.learn()의 회원이 되면 나만의 세미나와 그에 맞는 연습문제를 제작할 수 있습니다. 누구든지 환영합니다!"
        ]
    ]
}

function Main() {
    return (
        <div className="main">
            <MainIntroduction/>
            <hr className="mainSeparator"/>
            <MainDescription/>
        </div>
    );
}

export default Main;
