import '../css/Footer.css';

function Footer() {
    return (
        <>
            <hr className="separator"/>
            <div className="footer">
                <p>
                    Copyright <span>ⓒ</span> 2023 <a href="https://github.com/piz2a">piz2a</a>, <a href="https://github.com/junelee0997">junelee0997</a>.
                    All rights reserved.
                </p>
                <img src="/image/logo.png" alt="footerLogo" style={{height: '36px'}}/>
            </div>
        </>
    );
}

export default Footer;
