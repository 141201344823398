import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import './App.css';
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import Main from "./elements/Main";
import {Login, Logout, SignUp, VerifyEmail, VerifyEmailSuccess} from "./elements/Auth";
import $ from 'jquery';
import NotFound from "./elements/NotFound";
import {MyPage} from "./elements/MyPage";

const backend_hostname = "https://api.sadalearn.mywire.org";
const DEBUG = false;

function App() {
    const [publicData, setPublicData] = useState({"loggedIn": false, "userData": {}});
    useEffect(() => {
        if (DEBUG) {
            setPublicData({"loggedIn": true, "userData": {"username": "admin"}});
            console.log("App useEffect Debug");
            console.log(publicData);
            return;
        }
        $.ajax({
            type: "GET",
            url: backend_hostname + "/profile/public",
            data: {},
            xhrFields: {
                withCredentials: true
            },
            dataType: "json",
            success: (data) => {
                setPublicData(data);
                console.log(data);
            },
            error: () => {
                console.log("Cannot connect to backend server");
            }
        });
    }, []);
    return (
        <BrowserRouter>
            <Header publicData={publicData}/>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/auth/login" element={<Login publicData={publicData}/>}/>
                <Route path="/auth/logout" element={<Logout publicData={publicData}/>}/>
                <Route path="/auth/signup" element={<SignUp publicData={publicData}/>}/>
                <Route path="/auth/verify-email" element={<VerifyEmail/>}/>
                <Route path="/auth/verify-email/success" element={<VerifyEmailSuccess/>}/>
                <Route path="/auth/forgot-password" element={<></>}/>
                <Route path="/profile/:id" element={<MyPage/>}/>
                <Route path="/problems" element={<></>}/>
                <Route path="/seminars" element={<></>}/>
                <Route path="/*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    )
}

export {backend_hostname, App};
