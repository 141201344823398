import '../css/NotFound.css';

function NotFound() {
    return (
        <div className="notFound">
            <h1>404</h1>
            <p>Not Found</p>
            <p>페이지를 찾을 수 없습니다.</p>
        </div>
    );
}

export default NotFound;
