import '../css/Header.css';
import {useEffect, useState} from "react";

// [Dropdown visibility] 0: invisible / 1: visible / 2: initial state

function Dropdown(props: { visibility: number; children: any; }) {
    const [visibilityAnimation, setVisibilityAnimation] = useState(2);
    const [repeat, setRepeat] = useState(setTimeout(() => {}));
    const [animationClass, setAnimationClass] = useState("");
    const getWidth = () => (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    useEffect(() => {
        if (props.visibility === 1) {
            clearTimeout(repeat);
            setRepeat(setTimeout(() => {}));
            setVisibilityAnimation(1);
            setAnimationClass('slide-fade-in-dropdown');
        } else if (props.visibility === 0) {
            setAnimationClass('slide-fade-out-dropdown');
            setRepeat(setTimeout(() => {
                setVisibilityAnimation(0);
            }, 400));
        }
    }, [props.visibility]);
    return (
        <div className={"menuContainer " + animationClass}>
            <div className="menu">
                {(visibilityAnimation === 1 || getWidth() > 700) && props.children}
            </div>
        </div>
    )
}

function Header(props: { publicData: { [name: string]: any } }) {
    const [dropdownVisibility, setDropdownVisibility] = useState(2);
    const toggle = () => {
        // @ts-ignore
        // document.querySelector('.menu').classList.toggle('active');
        setDropdownVisibility([1, 0, 1][dropdownVisibility]);
    };
    useEffect(() => {
        console.log('header useeffect');
        console.log(props.publicData);
    }, []);

    const buttonSvg = () => {
        const color = dropdownVisibility === 1 ? '#84cefd' : 'white';
        return (
            <svg width="36" height="39">
                <rect x="0" y="0" width="36" height="3" style={{fill: color}}/>
                <rect x="0" y="12" width="36" height="3" style={{fill: color}}/>
                <rect x="0" y="24" width="36" height="3" style={{fill: color}}/>
            </svg>
        );
    };
    return (
        <>
            <div className="header">
                <div className="top">
                    <a href="/"><img src="/image/logo.png" alt="logo"/></a>
                    <button onClick={toggle}>{buttonSvg()}</button>
                </div>
                <Dropdown visibility={dropdownVisibility}>
                    <ul className="navbar">
                        <li><a href="/problems">문제</a></li>
                        <li><a href="/seminars">세미나</a></li>
                    </ul>
                    <ul className="navbtn">
                        {/* <li><a href="/language">언어</a></li> */}
                        {
                            /* (() => {
                                console.log("jsx");
                                console.log(props.publicData);
                                return true;
                            })() && */
                            (props.publicData["loggedIn"] ?
                                <>
                                    <li><a href={`/profile/${props.publicData["userData"]["username"]}`}>마이페이지</a></li>
                                    <li><a href="/auth/logout">로그아웃</a></li>
                                </> : <>
                                    <li><a href={`/auth/login?next=${window.location.pathname}`}>로그인</a></li>
                                    <li><a href={`/auth/signup?next=${window.location.pathname}`}>회원가입</a></li>
                                </>)
                        }
                    </ul>
                </Dropdown>
            </div>
            <hr className="separator"/>
        </>
    );
}

export default Header;
