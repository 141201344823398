import '../css/Auth.css';
import {backend_hostname} from "../App";
import $ from "jquery";
import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faEnvelope, faLock, faUser, faUserLock} from "@fortawesome/free-solid-svg-icons";
import {IconDefinition} from "@fortawesome/free-brands-svg-icons";


interface InputEntryInterface {
    type: string;
    icon: IconDefinition;
    placeholder: string;
    name: string;
}

function CustomInput({type, icon, placeholder, name}: InputEntryInterface) {
    return (
        <div className="inputEntry">
            <div className="iconWrapper">
                <FontAwesomeIcon icon={icon}/>
            </div>
            <input type={type} placeholder={placeholder} name={name} required/>
        </div>
    );
}

export function Login(props: { publicData: { [name: string]: any } }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (props.publicData["loggedIn"]) {
            alert("이미 로그인되어 있습니다. 로그아웃하고 로그인을 다시 진행해 주세요.");
            navigate(-1);
        }
    }, [props.publicData]);
    return (
        <div className="auth">
            <h2>로그인</h2>
            <form action={backend_hostname + "/auth/login"} method="post">
                <div className="container">
                    <CustomInput type="text" icon={faEnvelope} placeholder="이메일" name="email"/>
                    <CustomInput type="password" icon={faLock} placeholder="비밀번호" name="password"/>
                    <div className="bottomContainer">
                        <label>
                            <input type="checkbox" checked={true} name="remember"/>
                            <span>로그인 정보 저장하기</span>
                        </label>
                        <button type="submit" className="customButton">로그인</button>
                    </div>
                </div>
                <div className="container">
                    <button type="button" className="backbtn customButton" onClick={() => navigate(-1)}>뒤로</button>
                    <span className="rightbottom"><a href="/auth/forgot-password">비밀번호 찾기</a></span>
                    <span className="rightbottom"><a href="/auth/signup">회원가입</a></span>
                </div>
            </form>
        </div>
    );
}

export function Logout(props: { publicData: { [name: string]: any } }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!props.publicData["loggedIn"]) {
            alert("현재 로그인되어 있지 않습니다.");
            navigate(-1);
        }
        window.location.href = backend_hostname + "/auth/logout";
    }, [props.publicData]);
    return (
        <div className="auth">
            <h2>로그아웃 중...</h2>
        </div>
    );
}

export function SignUp(props: { publicData: { [name: string]: any } }) {
    const passwordElement1 = 'input[name=password]';
    const passwordElement2 = 'input[name=confirm_password]';
    const navigate = useNavigate();
    // const formData = new FormData(document.getElementById("signup"));
    const checkId = async () => {
        let result;
        await $.ajax({
            type: "GET",
            url: backend_hostname + "/auth/check-id/" + $("input[name=username]").val(),
            data: {},
            dataType: "json",
            success: (data) => {
                result = data["duplicate"] === false;
            },
            error: () => {
                console.log("Unable to check id because of a problem of connection with backend server");
                result = null;
            }
        });
        return result;
    };
    const checkPassword = () => {
        return $(passwordElement1).val() === $(passwordElement2).val();
    }
    const bind_password = (element1: string, element2: string) => {
        const elem1 = $(element1);
        elem1.each(() => {
            if (elem1.val() !== undefined) {
                // @ts-ignore
                elem1.data('oldVal', elem1.val());
                elem1.on("propertychange change click keyup input paste", function () {
                    const value1 = elem1.val();
                    if (value1 !== undefined && $(element2).val() !== undefined && elem1.data('oldVal') !== value1) {
                        elem1.data('oldVal', value1);
                        // If password confirm changed
                        $('.passwordmatch').text(checkPassword() ? "비밀번호가 일치합니다" : "비밀번호가 일치하지 않습니다");
                    }
                });
            }
        });
    }
    const checkEntries = async (event: any) => {
        event.preventDefault();
        // check
        if (!await checkId()) {
            alert("이미 사용 중인 아이디입니다.");
            return;
        }
        if (!checkPassword()) {
            alert("비밀번호가 일치한지 확인해주세요.");
            return;
        }
        // @ts-ignore
        event.target.submit();
    }
    useEffect(() => {
        if (props.publicData["loggedIn"]) {
            alert("이미 로그인되어 있습니다. 로그아웃한 뒤 회원가입을 진행해 주세요.");
            navigate(-1);
        }
        $("input[name=username]").on("focus", () => {
            $(".idAvailable").text("");
        }).on("focusout", async function() {
            if ($(this).val() === "") return;
            const idAvailable = await checkId();
            if (idAvailable !== null) {
                $(".idAvailable").text(idAvailable ? "사용 가능한 아이디입니다." : "이미 사용 중인 아이디입니다.");
            }
        });
        bind_password(passwordElement1, passwordElement2);
        bind_password(passwordElement2, passwordElement1);
    }, [props.publicData]);
    return (
        <div className="auth">
            <h2>회원가입</h2>
            <form id="signup" method="post" action={backend_hostname + "/auth/signup"} onSubmit={checkEntries}>
                <div className="container">
                    <CustomInput type="text" icon={faAddressCard} placeholder="성명" name="name"/>
                    <CustomInput type="text" icon={faUser} placeholder="아이디" name="username"/>
                    <div className="idAvailable"></div>
                    <CustomInput type="text" icon={faEnvelope} placeholder="이메일" name="email"/>
                    <CustomInput type="password" icon={faLock} placeholder="비밀번호" name="password"/>
                    <CustomInput type="password" icon={faUserLock} placeholder="비밀번호 확인" name="confirm_password"/>
                    <div className="passwordmatch"></div>

                    <div className="bottomContainer">
                        <button type="submit" className="customButton">회원가입</button>
                    </div>
                </div>
                <div className="container">
                    <button type="button" className="backbtn customButton" onClick={() => navigate(-1)}>뒤로</button>
                    <span className="rightbottom">이미 회원가입이 되어 있나요? <a href="/auth/login">로그인하기</a></span>
                </div>
            </form>
        </div>
    );
}

function RequestVerification() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        $.ajax({
            type: "POST",
            url: backend_hostname + "/auth/verify-email",
            data: {
                verificationCode: searchParams.get("verificationCode")
            },
            dataType: "json",
            success: () => {
                navigate("/auth/verify-email/success");
            },
            error: () => {
                $(".requestVerification").text("Unable to connect to backend server. Try again later.");
            }
        })
    });
    return <div className="requestVerification"></div>;
}

export function VerifyEmail() {
    const [searchParams] = useSearchParams();
    if (searchParams.get("verificationCode") == null) {
        return (
            <div className="auth">
                <h2>회원가입이 거의 완료되었습니다!</h2>
                <p>이메일을 확인하여 이메일 인증을 해 주세요.</p>
                <p>이메일이 보이지 않을 경우 스팸 메일함을 확인해 주세요.</p>
            </div>
        );
    }
    return <RequestVerification/>;
}

export function VerifyEmailSuccess() {
    const navigate = useNavigate();
    return (
        <div className="auth">
            <h2>이메일 인증에 성공하였습니다</h2>
            <button className="customButton backToLogin" onClick={() => navigate("/auth/login?next=/")}>로그인하기</button>
        </div>
    );
}